import React from "react";
import { useNavigate } from "react-router-dom";
import { Center, Environment, Grid, OrbitControls } from "@react-three/drei";
import { Canvas } from "@react-three/fiber";
import SetSceneBackgroundColor from "../components/SetSceneBackgroundColor";
import Result from "../components/Result";
import { useGenerations } from "../contexts/GenerationsContext";
import { Button } from "@nextui-org/button";

function Generations() {
  const navigate = useNavigate();
  const {
    generations,
    loading,
    handlePreviousPage,
    handleNextPage,
    currentPage,
    totalPages,
  } = useGenerations();

  const gridConfig = {
    gridSize: [10.5, 10.5],
    cellSize: 0.6,
    cellThickness: 1,
    cellColor: "#9d4b4b",
    sectionSize: 3.3,
    sectionThickness: 1.5,
    sectionColor: "#9d4b4b",
    fadeDistance: 25,
    fadeStrength: 1,
    followCamera: false,
    infiniteGrid: true,
  };
  const { gridSize, ...restGridConfig } = gridConfig;

  return (
    <div className="h-full overflow-auto pb-20">
      <h1 className="text-white pl-14 pt-10 text-3xl font-bold">
        Generation Library
      </h1>

      <div className="max-w-[850px]">
        <div className="grid grid-cols-3 gap-10 p-14 pb-8 max-w-[850px]">
          {generations.map((result, index) => (
            <div key={index} className="aspect-square min-h-40 relative">
              <Canvas
                shadows
                camera={{ position: [10, 12, 12], fov: 25 }}
                gl={{ alpha: false }}
                style={{ background: "#303035" }}
                className={"rounded-2xl"}
              >
                <SetSceneBackgroundColor />
                <group position={[0, -0.5, 0]}>
                  <Center top>
                    <Result url={result} showWireframe={false} />
                  </Center>
                  <Grid
                    position={[0, -0.01, 0]}
                    args={[gridSize[0], gridSize[1]]}
                    {...restGridConfig}
                  />
                </group>
                <OrbitControls makeDefault />
                <Environment
                  files="potsdamer_platz_1k.hdr"
                  background={false}
                />
              </Canvas>

              <Button
                size="sm"
                className="absolute bottom-2 right-2 bg-blue-500 text-white hover:bg-blue-600 text-xs h-6"
                onPress={() => navigate("/", { state: { result } })}
              >
                Export
              </Button>
            </div>
          ))}
        </div>

        <div className="pagination-buttons text-white flex justify-center gap-4 mb-8">
          <button
            onClick={handlePreviousPage}
            disabled={currentPage === 1}
            className="px-4 py-2 text-blue-500 rounded disabled:text-gray-500 flex items-center gap-2"
          >
            <span>&larr;</span> Previous
          </button>
          <button
            onClick={handleNextPage}
            disabled={currentPage === totalPages}
            className="px-4 py-2 text-blue-500 rounded disabled:text-gray-500 flex items-center gap-2"
          >
            Next <span>&rarr;</span>
          </button>
        </div>

        {/* Create Generation Section */}
        <div className="px-14 flex flex-col gap-10">
          <div className="bg-white/10 rounded-lg p-8">
            <h2 className="text-white text-xl font-semibold mb-4">Create</h2>
            <button
              onClick={() => navigate("/")}
              className="px-6 py-3 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700 transition-all duration-200 font-medium"
            >
              Create a New Generation
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Generations;
