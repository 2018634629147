import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import { Canvas, useFrame } from "@react-three/fiber";
import {
  AccumulativeShadows,
  Center,
  Environment,
  GizmoHelper,
  GizmoViewport,
  Grid,
  OrbitControls,
  RandomizedLight,
  useGLTF,
} from "@react-three/drei";
import { doc, onSnapshot } from "firebase/firestore";
import { db } from "./firebase-config";
import * as THREE from "three";
import { useAuth } from "./contexts/AuthContext";
import { createUserGenerationDoc } from "./handlers/db";
import { GenerationTrigger, ToastState } from "./types";
import { AnimatePresence, motion } from "framer-motion";
import Header from "./components/Header";
import {
  BoxIcon,
  EdgeIcon,
  PolyIcon,
  UploadIcon,
  VertexIcon,
} from "./components/Icons";
import { Progress } from "@nextui-org/progress";
import Toast from "./components/MessageToast";
import { NavLink, useLocation } from "react-router-dom";
import SetSceneBackgroundColor from "./components/SetSceneBackgroundColor";
import Result from "./components/Result";
import SidebarArrow from "./components/SidebarArrow";
import { Accordion, AccordionItem, Slider } from "@nextui-org/react";
import PaywallModal from "./components/PaywallModal";
import TextToImage from "./components/TextToImage";

// Add new components
const Shadows = memo(() => (
  <AccumulativeShadows
    temporal
    frames={100}
    color="#9d4b4b"
    colorBlend={0.5}
    alphaTest={0.7}
    scale={20}
  >
    <RandomizedLight amount={8} radius={4} position={[5, 5, -10]} />
  </AccumulativeShadows>
));

// @ts-ignore
function Suzi(props) {
  const { nodes } = useGLTF(
    "https://vazxmixjsiawhamofees.supabase.co/storage/v1/object/public/models/suzanne-high-poly/model.gltf",
  );
  return (
    // @ts-ignore
    <mesh castShadow receiveShadow geometry={nodes.Suzanne.geometry} {...props}>
      <meshStandardMaterial color="#9d4b4b" />
    </mesh>
  );
}

type ACTIVE = "IMAGE" | "TEXT" | null;

// Add this helper function at the top level
const downloadFile = async (url: string, fileName: string = "model.glb") => {
  const response = await fetch(url);
  const blob = await response.blob();
  const downloadUrl = URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = downloadUrl;
  a.download = fileName;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  URL.revokeObjectURL(downloadUrl);
};

// Add this new component for the spinning cubes
function SpinningCube({
  position,
  loading,
  tilted = false,
}: {
  position: [number, number, number];
  loading: boolean;
  tilted?: boolean;
}) {
  const meshRef = useRef<THREE.Mesh>(null);
  const speedRef = useRef(3); // Initial speed

  useFrame((state, delta) => {
    if (loading && meshRef.current) {
      speedRef.current += 0.2 * delta;
      meshRef.current.rotation.y += speedRef.current * delta;
    } else {
      speedRef.current = 3;
    }
  });

  return (
    <Center top position={position}>
      <mesh
        ref={meshRef}
        castShadow
        rotation={tilted ? [Math.PI / 4, 0, Math.PI / 4] : [0, 0, 0]}
      >
        <boxGeometry args={[0.7, 0.7, 0.7]} />
        <meshStandardMaterial color="#9d4b4b" />
      </mesh>
    </Center>
  );
}

// Add this new component before FloatingToolbar
function PolyCount({
  modelRef,
  modelLoaded,
  meshURL,  // URL of the current mesh
  onMeshUpdate,
  showToastMessage,
}: {
  modelRef: React.RefObject<THREE.Group>;
  modelLoaded: boolean;
  meshURL: string;
  onMeshUpdate: (meshURL: string) => void;
  showToastMessage: (message: string, duration?: number) => void;
}) {
  const [counts, setCounts] = useState({ vertices: 0, edges: 0, polys: 0 });
  const [reductionPercentage, setReductionPercentage] = useState(25);
  const [isReducing, setIsReducing] = useState(false);

  useEffect(() => {
    setReductionPercentage(25); 
  }, [meshURL]);


  const handleReduceFaces = async () => {
    try {
      setIsReducing(true);
      showToastMessage("Reducing Mesh Faces...");

      console.log("Starting reduction with:", {
        mesh_url: meshURL,
        reduction_percent: 100-reductionPercentage
      });

      // Reduced model from pymeshlab endpoint
      const response = await fetch('https://hassantsyed--instant3d-blender-api-fastapi-app.modal.run/simplify-mesh', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          mesh_url: meshURL,
          reduction_percent: 100-reductionPercentage
        }),
      });
      
      if (!response.ok) {
        const errorData = await response.text();
        console.error('API Error:', errorData);
        throw new Error('Failed to reduce mesh');
      }
      
      const reducedBlob = await response.blob();
      
      // Get upload URL for the reduced mesh
      const uploadMeshReducedResponse = await fetch(
        "https://3d-serverless.vercel.app/api/get-upload-urls",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            containerName: "3dstudio",
            fileNames: ["reduced-result.glb"], // Creates new file for each reduction
          }),
        }
      );

      const data = await uploadMeshReducedResponse.json();
      console.log('Upload URL Response:', data);

      // Upload the reduced mesh URL to storage to perform operations on it later
      await fetch(data.uploadUrls[0], {
        method: "PUT",
        headers: {
          "x-ms-blob-type": "BlockBlob",
          "Content-Type": "model/gltf-binary",
        },
        body: reducedBlob,
      });

      // Update URLs to point to newly reduced mesh
      const reducedMeshURL = data.uploadUrls[0].split('?')[0];
      console.log('Final URL:', reducedMeshURL);
      
      onMeshUpdate(reducedMeshURL);      // Update parent component

    } catch (error) {
      console.error('Error reducing mesh:', error);
      showToastMessage("Error reducing mesh. Please try again.");
    } finally {
      setIsReducing(false);
    }
  };

  useEffect(() => {
    console.log("PolyCount effect running");
    console.log("modelRef.current:", modelRef.current);

    if (modelLoaded && modelRef.current) {
      let totalVertices = 0;
      let totalPolys = 0;
      let totalEdges = 0;
      console.log("Starting traverse");
      modelRef.current.traverse((child) => {
        if (child instanceof THREE.Mesh && child.geometry) {
          const geometry = child.geometry;
          // Vertex count
          totalVertices += geometry.attributes.position.count;
          // Poly (triangle) count
          totalPolys += geometry.index
            ? geometry.index.count / 3
            : geometry.attributes.position.count / 3;
          // Edge count (approximate: each triangle has 3 edges, but edges are shared)
          totalEdges += geometry.index
            ? geometry.index.count
            : geometry.attributes.position.count;
        }
      });

      console.log("Counts calculated:", {
        totalVertices,
        totalPolys,
        totalEdges,
      });
      setCounts({
        vertices: Math.round(totalVertices),
        edges: Math.round(totalEdges / 2), // Divide by 2 as edges are shared
        polys: Math.round(totalPolys),
      });
    }
  }, [modelLoaded, modelRef, meshURL]);

  return (
    <div className="absolute top-4 right-4 bg-black/30 backdrop-blur-md rounded-lg px-4 py-2 text-white text-sm z-50 border border-[#3a396f] w-[190px]">
      <div className="flex flex-col gap-1">
        <div className="text-indigo-200 flex justify-between">
          <span className="text-slate-400 flex gap-2 items-center">
            <VertexIcon />
            Vertices:
          </span>
          <span className="ml-3 font-medium">
            {counts.vertices.toLocaleString()}
          </span>
        </div>
        <div className="text-indigo-200 flex justify-between">
          <span className="text-slate-400 flex gap-2 items-center">
            <EdgeIcon />
            Edges:
          </span>
          <span className="ml-3 font-medium">
            {counts.edges.toLocaleString()}
          </span>
        </div>
        <div className="text-indigo-200 flex justify-between">
          <span className="text-slate-400 flex gap-2 items-center">
            <PolyIcon />
            Polys:
          </span>
          <span className="ml-3 font-medium">
            {counts.polys.toLocaleString()}
          </span>
        </div>
      </div>

      {/* Reduce Faces Slider */}
      <div className="mt-4 border-t border-[#3a396f] pt-4">
        <div className="text-slate-300 mb-2">
            Reduce Faces by {reductionPercentage}%
          </div>
          <Slider
            value={reductionPercentage}
            onChange={(value) => setReductionPercentage(Number(value))}
            minValue={1}
            maxValue={100}
            step={1}
            className="max-w-md"
            classNames={{
              base: "max-w-md",
              track: "bg-white !h-1",
              filler: "bg-indigo-600",
              thumb: "bg-gray-500 cursor-pointer !w-4 !h-4 -translate-y-1/2 top-1/2",
            }}            
          />
          <button
            onClick={handleReduceFaces}
            disabled={isReducing}
            className="w-full mt-3 bg-indigo-600 hover:bg-indigo-700 text-white py-2 rounded-md transition-colors"
          >
            {isReducing ? (
              <div className="flex items-center justify-center gap-2">
                <div className="w-4 h-4 border-2 border-white border-t-transparent rounded-full animate-spin" />
                <span>Reducing...</span>
              </div>
            ) : (
              'Reduce Faces'
            )}
          </button>
      </div>
    </div>
  );
}
// Update FloatingToolbar to render PolyCount separately
function FloatingToolbar({
  showWireframe,
  setShowWireframe,
}: {
  showWireframe: boolean;
  setShowWireframe: (show: boolean) => void;
}) {
  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      className="fixed bottom-8 left-1/2 transform -translate-x-1/2 z-50"
    >
      <button
        onClick={() => setShowWireframe(!showWireframe)}
        className={`
          px-4 py-2 rounded-full transition-all duration-200 flex items-center gap-2
          ${
            showWireframe
              ? "bg-indigo-600 text-white"
              : "border border-indigo-600 text-indigo-600 hover:bg-indigo-600/10"
          }
        `}
      >
        {showWireframe ? (
          <img
            src="/solid-cube.png"
            alt=""
            className={`w-4 h-4 brightness-0 invert`}
          />
        ) : (
          <img
            src="/wireframe-icon.png"
            alt=""
            className={`w-4 h-4 brightness-0 invert`}
          />
        )}
        {showWireframe ? "Solid" : "Wireframe"}
      </button>
    </motion.div>
  );
}

enum AccordionKeys {
  IMAGE_TO_3D = "ImageTo3D",
  TEXT_TO_IMAGE = "TextToImage",
}

function App() {
  // Replace useState with useControls
  const gridConfig = {
    gridSize: [10.5, 10.5],
    cellSize: 0.6,
    cellThickness: 1,
    cellColor: "#9d4b4b",
    sectionSize: 3.3,
    sectionThickness: 1.5,
    sectionColor: "#9d4b4b",
    fadeDistance: 25,
    fadeStrength: 1,
    followCamera: false,
    infiniteGrid: true,
  };
  const { gridSize, ...restGridConfig } = gridConfig;

  const location = useLocation();
  const [images, setImages] = useState<File[]>([]);
  const [previews, setPreviews] = useState<string[]>([]);
  const [pendingJob, setPendingJob] = useState<string | null>(null);
  const [meshLoading, setMeshLoading] = useState<boolean>(false);
  const [result, setResult] = useState<string | null>(
    location.state?.result || null,
  );
  const [paywallModal, setPaywallModal] = useState<boolean>(false);
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [showWireframe, setShowWireframe] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [modelLoaded, setModelLoaded] = useState(false);
  const [selectedAccordions, setSelectedAccordions] = useState<AccordionKeys[]>([AccordionKeys.IMAGE_TO_3D]);
  const [selectedFormat, setSelectedFormat] = useState("glb");
  const [isDownloading, setIsDownloading] = useState(false);
  const { currentUser, userLoading, logout, signInWithGoogle } = useAuth();
  console.log(currentUser);
  
  const [text, setText] = useState("");
  
  const meshRef = useRef<THREE.Group>(null);
  const [currentMeshUrl, setCurrentMeshUrl] = useState(result);

  // In the App component, update the state
  const [toast, setToast] = useState<ToastState>({ show: false, message: '' });

  // Helper function to show toast
  const showToastMessage = (message: string, duration = 5000) => {
    setToast({ show: true, message });
    setTimeout(() => setToast({ show: false, message: '' }), duration);
  };

  useEffect(() => {
    return () => {
      previews.forEach((previewUrl) => {
        URL.revokeObjectURL(previewUrl);
      });
    };
  }, [previews]);

  const handleMultipleFilesSelect = useCallback((files: File[]) => {
    const validFiles = files.filter(
      (file) => file.type === "image/png" || file.type === "image/jpeg",
    );

    if (validFiles.length === 0) {
      // alert("Please upload PNG or JPG images");
      return;
    }

    setImages(validFiles);

    // Generate previews
    const previewUrls = validFiles.map((file) => URL.createObjectURL(file));
    setPreviews(previewUrls);
  }, []);

  const handleDrop = useCallback(
    (e: React.DragEvent) => {
      e.preventDefault();
      const files = Array.from(e.dataTransfer.files);
      handleMultipleFilesSelect(files);
    },
    [handleMultipleFilesSelect],
  );

  const handleDragOver = useCallback((e: React.DragEvent) => {
    e.preventDefault();
  }, []);

  const handleFileInput = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.files && e.target.files.length > 0) {
        handleMultipleFilesSelect(Array.from(e.target.files));
      }
    },
    [handleMultipleFilesSelect],
  );

  const determineActiveInput = () => {
    if (images.length > 0) {
      return "IMAGE";
    }
    if (text.length > 0) {
      return "TEXT";
    }
    return null;
  };

  const triggerGeneration = async () => {
    if (images.length === 0) {
      alert("Please provide images first");
      return;
    }
    setModelLoaded(false);
    setMeshLoading(true);
    // Show toast with generation message
    showToastMessage("This can take a minute...");

    let curUser = currentUser;

    try {
      // If not logged in, wait for sign in to complete
      if (!currentUser) {
        curUser = await signInWithGoogle();
        // Add a small delay to ensure auth state is updated
        await new Promise((resolve) => setTimeout(resolve, 1000));
      }

      // Verify user is now logged in
      if (!curUser) {
        throw new Error("Authentication failed");
      }

      if (
        (curUser.plan === "FREE" || !curUser.plan) &&
        curUser.generationsLeft <= 0
      ) {
        setMeshLoading(false);
        setPaywallModal(true);
        return;
      }

      // Build fileNames array
      const fileNames = images.map((_, index) => `image${index + 1}.png`);

      // Get upload URLs for the images
      const uploadUrlResponse = await fetch(
        "https://3d-serverless.vercel.app/api/get-upload-urls",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            containerName: "3dstudio",
            fileNames,
          }),
        },
      );

      if (!uploadUrlResponse.ok) {
        throw new Error("Failed to get upload URLs");
      }

      const { uploadUrls, uploadDir } = await uploadUrlResponse.json();

      // Upload the images using the signed URLs
      const uploadPromises = images.map((image, index) => {
        return fetch(uploadUrls[index], {
          method: "PUT",
          headers: {
            "x-ms-blob-type": "BlockBlob",
            "Content-Type": image.type,
          },
          body: image,
        }).then((uploadResponse) => {
          if (!uploadResponse.ok) {
            throw new Error(`Failed to upload image ${index + 1}`);
          }
        });
      });
      await Promise.all(uploadPromises);

      console.log("Images uploaded successfully to directory:", uploadDir);

      if (!curUser) {
        throw new Error("User must be logged in");
      }

      const docId = await createUserGenerationDoc(curUser);
      // Extract just the document ID from the full path
      const generationId = docId.split("/").pop()!;

      const generationTrigger: GenerationTrigger = {
        docId,
        image: null,
        images: uploadUrls,
      };

      await fetch("https://3d-serverless.vercel.app/api/trigger-generation", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(generationTrigger),
      });
      setPendingJob(generationId);
    } catch (error) {
      setMeshLoading(false);
      console.error("Error uploading images:", error);
      alert("Failed to process request. Please try again.");
      return;
    }
  };

  const handleDownload = async () => {
    try {
      const urlToDownload = currentMeshUrl || result;
      if (!urlToDownload) {
        throw new Error("No model available to download");
      }
      setIsDownloading(true);
      const minDurationPromise = new Promise(resolve => setTimeout(resolve, 500));
      
      // Show conversion toast for non-GLB formats
      if (selectedFormat !== 'glb') {
        showToastMessage("Converting Model Format...", 3000);
      }

      const downloadPromise = (async () => {
        if (selectedFormat === "glb") {
          await downloadFile(urlToDownload, "model.glb");
          return;
        } else {
          const response = await fetch('https://hassantsyed--instant3d-blender-api-fastapi-app.modal.run/api/convert-mesh', {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              url: urlToDownload,
              downloadType: selectedFormat,
            }),
          });

          if (!response.ok) {
            const errorText = await response.text();
            throw new Error(`Conversion failed. Please try again. Response: ${errorText}`);
          }

          const blob = await response.blob();
          const fileName = `model.${selectedFormat}`;

          await downloadFile(URL.createObjectURL(blob), fileName);
        }
      })();
      await Promise.all([minDurationPromise, downloadPromise]);
    } catch (error) {
      console.error("Error downloading model: ", error);
      alert("Failed to convert model. Please try again.");
    } finally {
      setIsDownloading(false);
    }
  };

  const handleSelectTextGeneratedImage = useCallback(async(url: string) => {
    setPreviews([url]);
    const response = await fetch(url);
    const blob = await response.blob();
    const file = new File([blob], "generated-image.png", { type: "image/png" });
    setImages([file]);
    setSelectedAccordions([AccordionKeys.IMAGE_TO_3D]);
  }, []);

  const activeInput: ACTIVE = determineActiveInput();

  useEffect(() => {
    if (!pendingJob || !currentUser) return;

    const unsubscribe = onSnapshot(
      doc(db, "User", currentUser.uid, "Generation", pendingJob),
      (doc) => {
        const data = doc.data();
        if (data?.status === "DONE") {
          const modelUrl = `https://photoshopai.blob.core.windows.net/3dstudio/${data.dirPath}/result.glb`;
          setResult(modelUrl);
          setMeshLoading(false);
          setPendingJob(null);
        } else if (data?.status === "ERROR") {
          setMeshLoading(false);
          setPendingJob(null);
          alert("Generation failed. Please try again.");
        }
      },
    );

    return () => unsubscribe();
  }, [pendingJob, currentUser?.uid]);

  // Set currentMeshUrl to result when result changes
  useEffect(() => {
    setCurrentMeshUrl(result);
  }, [result]);

  return (
    <div className="relative w-full h-screen max-h-screen flex flex-col bg-black">
      <PaywallModal
        isOpen={paywallModal}
        onClose={() => setPaywallModal(false)}
        user={currentUser}
      />

      <Header />
      <SidebarArrow sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      <div className="relative h-full">
        {/*Left Navbar*/}
        <div
          className={`bg-black border-r-1 border-slate-400 h-full flex flex-col transition-all overflow-hidden bg-black/30 backdrop-blur-md absolute left-0 z-10`}
          style={{
            maxWidth: sidebarOpen ? "500px" : "20px",
            minWidth: sidebarOpen ? "500px" : "20px",
          }}
        >
          {/* Scrollable content area */}
          <div 
            className="flex-1 overflow-y-auto"
            style={{ opacity: sidebarOpen ? 1 : 0 }}
          >
            <div className="px-12 pt-8">
              <Accordion
                variant="splitted"
                hideIndicator
                itemClasses={{
                  base: "bg-white/10",
                  title: "text-center text-xl text-white font-bold data-[open=false]:text-red-400 data-[open=true]:text-white",
                }}
                selectedKeys={selectedAccordions}
                onSelectionChange={(key) =>
                  setSelectedAccordions(Array.from(key, String) as AccordionKeys[])
                }
              >
                <AccordionItem
                  key={AccordionKeys.IMAGE_TO_3D}
                  aria-label="Image to 3D"
                  title="Image to 3D"
                >
                  <>
                    <div className="text-center pt-2 pb-10">
                      <div className="text-white text-2xl font-semibold mb-4 select-none">
                        Try it for Free
                      </div>
                      <div className="text-slate-300 text-lg font-medium">
                        Upload an image or drop a file to generate a stunning 3D
                        model instantly
                      </div>
                    </div>

                    <div
                      className="border-3 border-dashed border-gray-300 rounded-lg p-8 mb-4 cursor-pointer"
                      onDrop={activeInput !== "TEXT" ? handleDrop : undefined}
                      onDragOver={
                        activeInput !== "TEXT" ? handleDragOver : undefined
                      }
                      onClick={
                        activeInput !== "TEXT"
                          ? () => document.getElementById("fileInput")?.click()
                          : undefined
                      }
                      style={{
                        opacity: activeInput === "TEXT" ? 0.5 : 1,
                        pointerEvents: activeInput === "TEXT" ? "none" : "auto",
                      }}
                    >
                      <input
                        type="file"
                        id="fileInput"
                        accept="image/png,image/jpeg"
                        multiple
                        onChange={handleFileInput}
                        style={{ display: "none" }}
                      />
                      {previews.length > 0 ? (
                        <div className="relative">
                          <button
                            className="absolute top-[-20px] right-[-20px] w-6 h-6 bg-gray-200 rounded-full flex items-center justify-center hover:bg-gray-300 transition-colors"
                            onClick={(e) => {
                              e.stopPropagation(); // Prevent opening file dialog
                              setImages([]);
                              previews.forEach((previewUrl) => {
                                URL.revokeObjectURL(previewUrl);
                              });
                              setPreviews([]);
                            }}
                          >
                            ✕
                          </button>
                          <div className="flex flex-wrap gap-4 justify-center">
                            {previews.map((previewUrl, index) => (
                              <img
                                key={index}
                                src={previewUrl}
                                alt={`Preview ${index + 1}`}
                                style={{
                                  maxWidth: "100px",
                                  maxHeight: "100px",
                                  objectFit: "contain",
                                }}
                              />
                            ))}
                          </div>
                        </div>
                      ) : (
                        <div className="flex justify-center flex-col items-center">
                          <div className="rounded-full p-4 align-middle justify-self-center bg-indigo-600/20 mb-6">
                            <UploadIcon />
                          </div>
                          <p className="text-slate-300 text-lg font-semibold">
                            Drop your image(s) here
                          </p>
                          <p className="text-slate-400 text-lg font-medium mb-6">
                            or click to browse
                          </p>

                          <button className="bg-indigo-600 text-white px-8 py-2 rounded-md cursor-pointer shadow-md hover:shadow-lg transition-shadow">
                            Browse
                          </button>
                        </div>
                      )}
                    </div>
                    <button
                      className={`w-full mt-4 ${
                        !images || meshLoading
                          ? "bg-gray-400 cursor-not-allowed text-gray-600"
                          : "bg-indigo-600 hover:bg-indigo-700 hover:shadow-lg text-white"
                      } text-white border-none py-3 rounded transition-all duration-200 ease-in-out`}
                      onClick={triggerGeneration}
                      disabled={!images || meshLoading}
                    >
                      {meshLoading ? (
                        <div className="flex items-center justify-center gap-2">
                          <div className="w-5 h-5 border-2 border-white border-t-transparent rounded-full animate-spin" />
                          <span>Generating...</span>
                        </div>
                      ) : (
                        <div className="flex gap-2 w-full justify-center">
                          <BoxIcon /> Generate 3D Model
                        </div>
                      )}
                    </button>

                    {result && (
                      <>
                        {/* Format Selection Buttons */}
                        <div className="flex justify-center mt-4 mb-2">
                          <div className="flex border border-indigo-600 rounded-md overflow-hidden">
                            {["glb", "fbx", "obj"].map((format, index) => (
                              <button
                                key={format}
                                onClick={() => setSelectedFormat(format)}
                                className={`px-10 py-1.5 font-semibold ${
                                  selectedFormat === format
                                    ? "bg-indigo-600 text-white"
                                    : "bg-transparent text-white hover:bg-indigo-600/10"
                                } ${
                                  index !== 2 ? "border-r border-indigo-600" : ""
                                }`}
                              >
                                {format.toUpperCase()}
                              </button>
                            ))}
                          </div>
                        </div>

                        {/* Download Button */}
                        <button
                          className="w-full mt-2 bg-neutral-800 hover:bg-neutral-700 hover:shadow-lg text-white border-none py-3 rounded transition-all duration-200 ease-in-out"
                          onClick={handleDownload}
                          disabled={isDownloading}
                        >
                          {isDownloading ? (
                            <div className="flex items-center justify-center gap-2">
                              <div className="w-4 h-4 border-2 border-white border-t-transparent rounded-full animate-spin" />
                              <span>Downloading...</span>
                            </div>
                          ) : (
                            "Download Model"
                          )}
                        </button>
                      </>
                    )}
                  </>
                </AccordionItem>
                <AccordionItem
                  key={AccordionKeys.TEXT_TO_IMAGE}
                  aria-label="Text to image"
                  title="Text to image"
                >
                  <TextToImage onSelectImage={handleSelectTextGeneratedImage} />
                </AccordionItem>
              </Accordion>
            </div>
          </div>

          {/* Fixed bottom section */}
          <div 
            className="border-t-1 border-slate-400 p-6 bg-black/30 backdrop-blur-md z-30"
            style={{ opacity: sidebarOpen ? 1 : 0 }}
          >
            {currentUser?.plan === "FREE" && (
              <div className="mb-6">
                <div className="flex justify-between mb-2">
                  <p className="text-slate-300 text-sm">Free Generations Left</p>
                  <p className="text-slate-300 text-sm">{currentUser.generationsLeft}/3</p>
                </div>

                <Progress
                  aria-label="Free generations left"
                  color="primary"
                  maxValue={3}
                  value={currentUser.generationsLeft}
                  classNames={{
                    base: "max-w-md bg-white rounded-full",
                    indicator: "bg-indigo-600",
                  }}
                />
              </div>
            )}

            {currentUser ? (
              <div className="text-indigo-400 hover:text-indigo-500 flex gap-2 text-lg cursor-pointer justify-center items-center font-medium">
                <NavLink to="/profile">Profile</NavLink>
              </div>
            ) : (
              <div
                className="text-indigo-400 hover:text-indigo-500 flex gap-2 text-lg cursor-pointer justify-center items-center font-medium"
                onClick={() => signInWithGoogle()}
              >
                <span>Sign In</span>
              </div>
            )}
          </div>
        </div>

        {result && (
          <>
            <FloatingToolbar
              showWireframe={showWireframe}
              setShowWireframe={setShowWireframe}
            />
            {!meshLoading && (
              <PolyCount 
                modelRef={meshRef} 
                modelLoaded={modelLoaded} 
                meshURL={result}
                onMeshUpdate={(newUrl) => {
                  setCurrentMeshUrl(newUrl); 
                  setModelLoaded(false);
                }}
                showToastMessage={showToastMessage}
              />
            )}
          </>
        )}

        <Canvas
          shadows
          camera={{ position: [10, 12, 12], fov: 25 }}
          gl={{ alpha: false }}
          style={{ background: "#303035" }}
        >
          <SetSceneBackgroundColor />
          <group position={[0, -0.5, 0]}>
            {result ? (
              <Center top>
                {currentMeshUrl && (
                <Result
                  url={currentMeshUrl || result}
                  showWireframe={showWireframe}
                  ref={meshRef}
                  onLoad={() => {
                    console.log("LOAD FIRING");
                    setModelLoaded(true);
                  }}
                />
                )}
              </Center>
            ) : (
              <>
                <Center top>
                  <Suzi rotation={[-0.63, 0, 0]} scale={2} />
                </Center>
                <SpinningCube
                  position={[-2, 0, 2]}
                  loading={meshLoading}
                  tilted={true}
                />
                <SpinningCube
                  position={[4, 0, 1]}
                  loading={meshLoading}
                  tilted={false}
                />
              </>
            )}
            {!result && <Shadows />}
            <Grid
              position={[0, -0.01, 0]}
              args={[gridSize[0], gridSize[1]]}
              {...restGridConfig}
            />
          </group>
          <OrbitControls makeDefault />
          <Environment files="potsdamer_platz_1k.hdr" background={false} />
          <GizmoHelper alignment="bottom-right" margin={[80, 80]}>
            <GizmoViewport
              axisColors={["#9d4b4b", "#9d4b4b", "#9d4b4b"]}
              labelColor="white"
            />
          </GizmoHelper>
        </Canvas>
      </div>

      <AnimatePresence>
        {toast.show && <Toast message={toast.message} />}
      </AnimatePresence>
    </div>
  );
}

export default App;