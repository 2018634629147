import { SERVERLESS_URL } from "../constants";

export const generateImageFromPrompt = async (prompt: string): Promise<string[]> => {
  const response = await fetch(`${SERVERLESS_URL}/api/image-generation`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      prompt
    })
  });

  if (!response.ok) {
    throw new Error('Failed to generate images');
  }

  const { images } = await response.json();
  console.log(images);
  return images;
};
