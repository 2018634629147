import React from "react";
import { ArrowIcon } from "./Icons";

interface Props {
  sidebarOpen: boolean;
  setSidebarOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const SidebarArrow: React.FC<Props> = ({ sidebarOpen, setSidebarOpen }) => {
  return (
    <div
      className="border-1 border-slate-400 rounded-full fixed top-28 z-20 w-8 h-8 flex justify-center items-center cursor-pointer bg-neutral-900 hover:bg-neutral-800 transition-all"
      style={{ left: sidebarOpen ? "484px" : "6px" }}
      onClick={() => {
        setSidebarOpen(!sidebarOpen);
      }}
    >
      <ArrowIcon
        className={`text-slate-400 ${sidebarOpen ? "" : "rotate-180"}`}
      />
    </div>
  );
};

export default SidebarArrow;
