import React from "react";

export default function BlenderPlugin() {
  return (
    <div>
      <h1 className="text-white pl-14 pt-10 text-3xl font-bold">
        Blender Plugin
      </h1>

      <div className="space-y-4 p-14 max-w-[850px]">
        <div className="bg-white/10 rounded-lg p-8 flex flex-col items-start">
          <h2 className="text-white text-2xl font-semibold mb-1">
            Plugin
          </h2>
          <p className="text-white  mb-4">Version 1.0.0</p>
          {/* Original download link - uncomment when ready */}
          <a
            href="https://photoshopai.blob.core.windows.net/3dstudio/plugins/instant3d_1_0_0.zip"
            download
            className="inline-block px-6 py-3 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700 transition-all duration-200 font-medium"
          >
            Download
          </a>
        </div>

        <div className="bg-white/10 rounded-lg p-8 flex flex-col items-start">
          <h2 className="text-white text-2xl font-semibold mb-4">
            Tutorials
          </h2>
          <div className="w-full space-y-4">
            <div className="aspect-video">
              <iframe
                className="w-full h-full rounded-lg"
                src="https://www.youtube.com/embed/p8yrZ6qQUc8"
                title="Blender Plugin Tutorial 1"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
            <div className="aspect-video">
              <iframe
                className="w-full h-full rounded-lg"
                src="https://www.youtube.com/embed/WbvlkotcrBo"
                title="Blender Plugin Tutorial 2"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
