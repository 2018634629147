import React, { useState } from "react";
import { Spinner, Textarea } from "@nextui-org/react";
import { Button } from "@nextui-org/button";
import Toast from "./MessageToast";
import { generateImageFromPrompt } from "../handlers/ImageGen";
import { useAuth } from "../contexts/AuthContext";

interface Props {
  onSelectImage: (imageUrl: string) => void;
}

const TextToImage: React.FC<Props> = ({ onSelectImage }) => {
  const [prompt, setPrompt] = useState("");
  const [loading, setLoading] = useState(false);
  const [imageUrls, setImageUrls] = useState<string[]>([]);
  const [toast, setToast] = useState<string | null>(null);

  const { currentUser, signInWithGoogle } = useAuth();

  const onGenerate = async () => {
    if (!prompt || loading) return;

    let user = currentUser;
    if (!user) {
      try {
        user = await signInWithGoogle();
      } catch (error) {
        setToast("Failed to sign in with Google");
        setTimeout(() => setToast(null), 5000);
        return;
      }
      await new Promise((resolve) => setTimeout(resolve, 1000));

      if (!user) {
        setToast("Authentication failed");
        setTimeout(() => setToast(null), 5000);
        return;
      }
    }

    setLoading(true);
    setImageUrls([]);
    try {
      const urls = await generateImageFromPrompt(prompt);
      setImageUrls(urls);
    } catch (error) {
      setToast("Failed to generate image");
      setTimeout(() => setToast(null), 5000);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="mb-6 space-y-4">
      {toast && <Toast message={toast} />}
      <Textarea
        label="Prompt to generate an image"
        labelPlacement="outside"
        placeholder="Enter prompt"
        variant="bordered"
        fullWidth
        minRows={5}
        value={prompt}
        onChange={(e) => setPrompt(e.target.value)}
        classNames={{
          label: "text-white mb-2",
          inputWrapper: " bg-white !h-auto",
          input: "outline-none",
        }}
      />

      <div className="flex justify-end">
        <Button
          className="bg-indigo-600 text-white"
          onPress={onGenerate}
          disabled={loading || !prompt}
        >
          {loading ? <Spinner color="white" /> : "Generate"}
        </Button>
      </div>

      {imageUrls.length > 0 && (
        <div className="mt-4">
          <p className="text-white">Result:</p>
          <div className="grid grid-cols-2 gap-4">
            {imageUrls.map((url, index) => (
              <div key={index} className="relative min-h-[120px]">
                <img
                  src={url}
                  alt={`Generated ${index}`}
                  className="w-full h-auto rounded-md"
                />
                <button
                  onClick={() => onSelectImage(url)}
                  className="absolute bottom-2 right-2 bg-indigo-600 hover:bg-indigo-700 transition-all text-white px-2 py-1 rounded-md text-xs"
                >
                  Make 3D
                </button>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default TextToImage;
