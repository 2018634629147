import React from "react";
import { useAuth } from "../contexts/AuthContext";
import { LogoutIcon } from "../components/Icons";

function Settings() {
  const { logout } = useAuth();

  return (
    <>
      <h1 className="text-white pl-14 pt-10 text-3xl font-bold">Settings</h1>

      <div className="p-14 flex flex-col gap-10 max-w-[850px]">
        {/* Support Section */}
        <div className="bg-white/10 rounded-lg p-8">
          <h2 className="text-white text-xl font-semibold mb-4">Support</h2>
          <p className="text-slate-300">
            Need help? Contact our support team at{" "}
            <a
              href="mailto:hello@exafloplabs.com"
              className="text-indigo-400 hover:text-indigo-300 transition-colors"
            >
              hello@exafloplabs.com
            </a>
          </p>
        </div>

        {/* Account Actions Section */}
        <div className="bg-white/10 rounded-lg p-8">
          <h2 className="text-white text-xl font-semibold mb-4">
            Account Actions
          </h2>
          <button
            onClick={() => logout()}
            className="flex items-center gap-2 px-6 py-3 bg-red-500/20 text-red-400 rounded-lg hover:bg-red-500/30 transition-all duration-200"
          >
            <LogoutIcon />
            <span>Logout</span>
          </button>
        </div>
      </div>
    </>
  );
}

export default Settings;
