import React, { useState } from "react";
import { useAuth } from "../contexts/AuthContext";
import { addApiKey, removeApiKey } from "../handlers/db";
import Toast from "../components/MessageToast";
import { v4 as uuidv4 } from "uuid";
import { CopyIcon } from "../components/Icons";

export default function APIKeys() {
  const { currentUser } = useAuth();
  const [isGenerating, setIsGenerating] = useState(false);
  const [toast, setToast] = useState<string | null>(null);

  const generateNewKey = async () => {
    if (!currentUser) return;

    setIsGenerating(true);
    try {
      const newKey = "pk_" + uuidv4().replace(/-/g, "");
      await addApiKey(currentUser, newKey);

      setToast("New API key generated successfully");
      setTimeout(() => setToast(null), 3000);
    } catch (error) {
      console.error("Error generating API key:", error);
      setToast("Failed to generate API key");
      setTimeout(() => setToast(null), 3000);
    } finally {
      setIsGenerating(false);
    }
  };

  const deleteKey = async (keyToDelete: string) => {
    if (!currentUser) return;

    try {
      await removeApiKey(currentUser, keyToDelete);
      setToast("API key deleted successfully");
      setTimeout(() => setToast(null), 3000);
    } catch (error) {
      console.error("Error deleting API key:", error);
      setToast("Failed to delete API key");
      setTimeout(() => setToast(null), 3000);
    }
  };

  return (
    <div className=" max-w-[850px]">
      {toast && <Toast message={toast} />}

      <div className="flex justify-between items-center pt-10 pr-14 ">
        <h1 className="text-white pl-14  text-3xl font-bold">API Keys</h1>
        <button
          onClick={generateNewKey}
          disabled={isGenerating}
          className="bg-indigo-600 hover:bg-indigo-700 text-white px-4 py-2 rounded-md disabled:opacity-50 transition-all duration-200"
        >
          {isGenerating ? "Generating..." : "Generate New Key"}
        </button>
      </div>

      <div className="space-y-4 p-14">
        {currentUser?.apiKeys?.length === 0 && (
          <p className="text-gray-400">
            No API keys found. Generate one to get started.
          </p>
        )}

        {currentUser?.apiKeys?.map((key) => (
          <div
            key={key}
            className="bg-white/10 p-4 rounded-md flex justify-between items-center"
          >
            <div>
              <button
                onClick={() => {
                  navigator.clipboard.writeText(key);
                  setToast("API key copied to clipboard");
                }}
                className="text-indigo-400 hover:text-indigo-500 mr-4"
              >
                <CopyIcon />
              </button>
              <code className="text-gray-300 font-mono">{key}</code>
            </div>
            <button
              onClick={() => deleteKey(key)}
              className="text-red-400 hover:text-red-500"
            >
              Delete
            </button>
          </div>
        ))}
      </div>
    </div>
  );
}
