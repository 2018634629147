import { Routes, Route } from 'react-router-dom';
import App from "./App";
// import Profile from "./Profile";
import ProfilePage from './pages/ProfilePage';

const Router = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="/profile" element={<ProfilePage />} />
      </Routes>
    </>
  );
};

export default Router;