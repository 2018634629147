import { useLoader, useThree } from "@react-three/fiber";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import React, { useEffect, forwardRef } from "react";
import * as THREE from "three";
import { Edges } from "@react-three/drei";

const Result = forwardRef<THREE.Group, { 
  url: string; 
  showWireframe: boolean;
  onLoad?: () => void;
}>(
  ({ url, showWireframe, onLoad }, ref) => {
    const gltf = useLoader(GLTFLoader, url);
    const { camera } = useThree();

    // First useEffect: Camera setup (runs only once when model loads)
    useEffect(() => {
      const box = new THREE.Box3().setFromObject(gltf.scene);
      const center = box.getCenter(new THREE.Vector3());
      const size = box.getSize(new THREE.Vector3());
    
      if (camera instanceof THREE.PerspectiveCamera) {
        const maxDim = Math.max(size.x, size.y, size.z);
        const fov = (camera.fov * Math.PI) / 180;
        const distance = maxDim / (2 * Math.tan(fov / 2));
    
        camera.position.set(
          center.x + distance,
          center.y + distance,
          center.z + distance,
        );
      }
      camera.lookAt(center);
      camera.updateProjectionMatrix();
    
      // Call onLoad callback if provided
      onLoad?.();
    }, [gltf, camera]);

    // Second useEffect: Material updates
    useEffect(() => {
      gltf.scene.traverse((child) => {
        if (child instanceof THREE.Mesh) {
          // Store the original material if not already stored
          if (!child.userData.originalMaterial) {
            child.userData.originalMaterial = child.material;
          }

          // Reset to the original material
          child.material = child.userData.originalMaterial;

          if (showWireframe) {
            // Modify the material to be transparent
            child.material.transparent = true;
            child.material.opacity = 0.3;

            // Ensure correct rendering order
            child.renderOrder = 1;

            // Ensure material updates are applied
            child.material.needsUpdate = true;
          } else {
            // Restore the material properties when wireframe is off
            child.material.transparent = false;
            child.material.opacity = 1.0;

            child.renderOrder = 0;

            child.material.needsUpdate = true;
          }
        }
      });
    }, [gltf.scene, showWireframe]);

    // Modified renderScene to pass ref to top-level group
    const renderScene = (object: THREE.Object3D) => {
      return (
        <group ref={ref}>
          {object.children.map((child) => {
            if (child instanceof THREE.Mesh) {
              return (
                <mesh
                  key={child.uuid}
                  geometry={child.geometry}
                  material={child.material}
                  position={child.position}
                  rotation={child.rotation}
                  scale={child.scale}
                  renderOrder={child.renderOrder}
                >
                  {showWireframe && (
                    <Edges scale={1} threshold={1} color="#9d4b4b" />
                  )}
                </mesh>
              );
            } else {
              return renderScene(child);
            }
          })}
        </group>
      );
    };

    return <>{renderScene(gltf.scene)}</>;
  }
);

export default Result;