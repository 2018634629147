import React from "react";
import { loadStripe } from "@stripe/stripe-js";
import {
  CustomisationIcon,
  UnlimitedIcon,
  ProcessingIcon,
  UsageIcon,
} from "./Icons";

const SERVER_URL = "https://classroom-be-production.up.railway.app";
const stripePromise = loadStripe(
  "pk_live_51Q1vmuELzxPZzuBzLbxFqUm1mzdKbiCVxzydMMpB1UQHqABSWQhdEBHugKmEGw08xkSN1sGkc1EyGpKwOxAvXEY700ngN0sBMo"
);

interface PaywallInlineProps {
  userId?: string;
  className?: string;
}

function PaywallInline({ userId, className = "" }: PaywallInlineProps) {
  const handleUpgrade = async () => {
    try {
      const response = await fetch(`${SERVER_URL}/create-checkout-session`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          uid: userId,
          price_id: "price_1QUE54ELzxPZzuBzn77mOyxW",
        }),
      });

      const session = await response.json();
      const stripe = await stripePromise;
      const { error } = await stripe!.redirectToCheckout({
        sessionId: session.sessionId,
      });

      if (error) {
        console.error("Error:", error);
      }
    } catch (error) {
      console.error("Error creating Stripe session:", error);
    }
  };

  return (
    <div className={`text-center ${className}`}>
      <h2 className="text-3xl font-bold mb-3 text-indigo-100">Upgrade to Pro</h2>
      <p className="mt-2 text-indigo-200">
        Get unlimited generations. Upgrade to Pro today
      </p>

      <div className="mb-6 mt-6 p-2 bg-indigo-50/5 rounded-lg border border-[#5856B3] text-center w-max m-auto">
        <div className="relative text-indigo-200 text-center flex justify-center items-center">
          <div className="text-3xl font-semibold">$10</div>
          <div className="text-xl font-normal">/month</div>
        </div>
      </div>

      <div className="flex gap-4 items-center mt-10">
        <div className="flex-1 h-px border border-indigo-400"></div>
        <div className="text-center text-indigo-300 text-sm text-nowrap">
          Included in the plan
        </div>
        <div className="flex-1 h-px border border-indigo-400"></div>
      </div>

      <div className="p-6 my-6 bg-indigo-50/5 rounded-2xl border border-[#3a396f]">
        <ul className="text-left space-y-3">
          <div className="text-indigo-200 flex gap-2">
            <UnlimitedIcon />
            <span className="text-base">Unlimited 3D generations</span>
          </div>

          <div className="text-indigo-200 flex gap-2">
            <CustomisationIcon />
            <span className="text-base">Advanced customization options</span>
          </div>

          <div className="text-indigo-200 flex gap-2">
            <ProcessingIcon />
            <span className="text-base">Priority processing</span>
          </div>

          <div className="text-indigo-200 flex gap-2">
            <UsageIcon />
            <span className="text-base">Commercial usage rights</span>
          </div>
        </ul>
      </div>

      <button
        onClick={handleUpgrade}
        className="w-full bg-indigo-600 text-white py-3 px-6 rounded-lg font-semibold hover:bg-indigo-500 transition-all duration-200 shadow-lg hover:shadow-xl"
      >
        Upgrade to Pro
      </button>
    </div>
  );
}

export default PaywallInline; 