import React, { useState } from "react";
import clsx from "clsx";
import { useAuth } from "../contexts/AuthContext";
import Header from "../components/Header";
import { ArrowIcon } from "../components/Icons";
import PageLoader from "../components/PageLoader";
import { ProfilePageTypes } from "../types";
import Billing from "./Billing";
import Settings from "./Settings";
import Generations from "./Generations";
import { GenerationsProvider } from "../contexts/GenerationsContext";
import APIKeys from "./APIKeys";
import BlenderPlugin from "./BlenderPlugin";

function ProfilePage() {
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [selectedPage, setSelectedPage] = useState<ProfilePageTypes>("PROFILE");

  const { currentUser, userLoading } = useAuth();

  const sidebarWidth = sidebarOpen ? 400 : 20;

  if (userLoading) {
    return <PageLoader />;
  } else if (currentUser == null) {
    window.location.href = "/";
    return null;
  }

  return (
    <div className="relative w-full h-screen max-h-screen flex flex-col bg-black overflow-hidden">
      <GenerationsProvider>
        <Header />
        {/* Arrow icon */}
        <div
          className="border-1 border-slate-400 rounded-full fixed top-28 z-20 w-8 h-8 flex justify-center items-center cursor-pointer bg-neutral-900 hover:bg-neutral-800 transition-all"
          style={{ left: sidebarOpen ? "384px" : "6px" }}
          onClick={() => {
            setSidebarOpen(!sidebarOpen);
          }}
        >
          <ArrowIcon
            className={`text-slate-400 ${sidebarOpen ? "" : "rotate-180"}`}
          />
        </div>

        <div className="relative h-full flex-1 flex overflow-hidden">
          <div
            className={`bg-black border-r-1 border-slate-400 h-full flex flex-col transition-all overflow-hidden bg-black/30 backdrop-blur-md absolute left-0 z-10`}
            style={{
              maxWidth: `${sidebarWidth}px`,
              minWidth: `${sidebarWidth}px`,
            }}
          >
            <div
              className="text-center flex-1 transition-all"
              style={{ opacity: sidebarOpen ? 1 : 0 }}
            >
              <ul className="w-full color-white text-lg text-left">
                <TabLink
                  name="PROFILE"
                  selectedPage={selectedPage}
                  setSelectedPage={setSelectedPage}
                >
                  Generations
                </TabLink>

                <TabLink
                  name="BLENDER_PLUGIN"
                  selectedPage={selectedPage}
                  setSelectedPage={setSelectedPage}
                >
                  Blender Plugin
                </TabLink>

                <TabLink
                  name="API_KEYS"
                  selectedPage={selectedPage}
                  setSelectedPage={setSelectedPage}
                >
                  API Keys
                </TabLink>

                <TabLink
                  name="BILLING"
                  selectedPage={selectedPage}
                  setSelectedPage={setSelectedPage}
                >
                  Billing
                </TabLink>

                <TabLink
                  name="SETTINGS"
                  selectedPage={selectedPage}
                  setSelectedPage={setSelectedPage}
                >
                  Settings
                </TabLink>
              </ul>
            </div>

            {currentUser && (
              <div
                className="border-t-1 border-slate-400 py-10 text-indigo-400 hover:text-indigo-500 flex gap-2 text-lg cursor-pointer justify-center items-center font-medium"
                onClick={() => (window.location.href = "/")}
              >
                <span>New Generation</span>
              </div>
            )}
          </div>

          <div
            style={{ paddingLeft: `${sidebarWidth}px` }}
            className="transition-all overflow-y-auto h-full flex-1"
          >
            {selectedPage === "BILLING" && <Billing />}
            {selectedPage === "SETTINGS" && <Settings />}
            {selectedPage === "BLENDER_PLUGIN" && <BlenderPlugin />}
            {selectedPage === "PROFILE" && <Generations />}
            {selectedPage === "API_KEYS" && <APIKeys />}
          </div>
        </div>
      </GenerationsProvider>
    </div>
  );
}

const TabLink: React.FC<{
  name: ProfilePageTypes;
  children: React.ReactNode;
  selectedPage: ProfilePageTypes;
  setSelectedPage: (page: ProfilePageTypes) => void;
}> = ({ name, children, selectedPage, setSelectedPage }) => {
  return (
    <li
      onClick={() => setSelectedPage(name)}
      className={clsx(
        "w-full py-6 text-white cursor-pointer bg-white/10 hover:bg-white/15 my-1 px-10",
        selectedPage === name ? "!text-red-400 bg-white/20" : "",
      )}
    >
      {children}
    </li>
  );
};

export default ProfilePage;
