import React from "react";
import { useAuth } from "../contexts/AuthContext";
import { Plan } from "../types";
import PaywallInline from "../components/PaywallInline";

function Billing() {
  const { currentUser } = useAuth();

  const getPlanDisplay = (plan: Plan | null | undefined) => {
    if (!plan) return "No Plan";
    return plan.charAt(0).toUpperCase() + plan.slice(1).toLowerCase();
  };

  const getPlanColor = (plan: Plan | null | undefined) => {
    switch (plan) {
      case "FREE":
        return "text-slate-300";
      case "STARTER":
        return "text-blue-400";
      case "PRO":
        return "text-indigo-400";
      case "ENTERPRISE":
        return "text-purple-400";
      default:
        return "text-slate-300";
    }
  };

  return (
    <>
      <h1 className="text-white pl-14 pt-10 text-3xl font-bold">Billing</h1>

      <div className="p-14 flex flex-col gap-10 max-w-[850px]">
        {/* Plan Status Section */}
        <div className="bg-white/10 rounded-lg p-8">
          <h2 className="text-white text-xl font-semibold mb-4">Current Plan</h2>
          <div className="flex flex-col gap-2">
            <div className="flex items-center gap-2">
              <span className="text-slate-300">Status:</span>
              <span className={getPlanColor(currentUser?.plan)}>
                {getPlanDisplay(currentUser?.plan)}
              </span>
            </div>
          </div>
        </div>

        {currentUser?.plan === "FREE" ? (
          <div className="bg-white/10 rounded-lg p-8">
            <PaywallInline userId={currentUser?.uid} />
          </div>
        ) : (
          <div className="bg-white/10 rounded-lg p-8">
            <h2 className="text-white text-xl font-semibold mb-4">Manage Plan</h2>
            <a
              href="https://billing.stripe.com/p/login/6oE6qb3qWaV1gtW6oo"
              target="_blank"
              rel="noopener noreferrer"
              className="inline-block px-6 py-3 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700 transition-all duration-200 font-medium"
            >
              Manage Billing
            </a>
          </div>
        )}
      </div>
    </>
  );
}

export default Billing;
