import React from "react";
import ReactDOM from "react-dom";
import { motion } from "framer-motion";

export default function Toast({ message }: { message: string }) {
  return ReactDOM.createPortal(
    <motion.div
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: 50 }}
      className="fixed bottom-24 left-1/2 transform -translate-x-1/2 bg-neutral-900 text-white px-6 py-3 rounded-lg shadow-lg z-[9999]"
    >
      {message}
    </motion.div>,
    // We portal the toast into the <body> (or a dedicated DOM node),
    // away from the sidebar or other stacking contexts.
    document.body
  );
}