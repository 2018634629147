import React from 'react';

export function PageLoader() {
  return (
    <div className="h-full w-full flex items-center justify-center bg-black">
      <div className="flex flex-col items-center gap-4">
        <div className="w-12 h-12 border-4 border-indigo-600 border-t-transparent rounded-full animate-spin" />
        <p className="text-slate-300 text-lg font-medium">Loading...</p>
      </div>
    </div>
  );
}

export default PageLoader;
