import React from "react";
import { MagicIcon, StarIcon, UserGroupIcon } from "./Icons";
import { Button } from "@nextui-org/button";
import { useAuth } from "../contexts/AuthContext";

const Header: React.FC = () => {
  const { currentUser, userLoading, signInWithGoogle } = useAuth();

  return (
    <div className="py-4 px-8 w-full relative bg-black flex items-center border-b-1 border-slate-400">
      <img 
        src="/instant3d-logo.svg" 
        alt="Instant 3D AI"
        className="h-16 flex-shrink-0 mr-16"
      />

      <div className="flex-1 flex items-center justify-evenly">
        <div className="flex gap-2">
          <UserGroupIcon />
          <div className="text-slate-300 text-lg font-medium whitespace-nowrap">
            Trusted by 1,400+ Creators
          </div>
        </div>

        <span className="text-zinc-600 text-lg font-medium">|</span>

        <div className="flex gap-2">
          <StarIcon />
          <div className="text-slate-300 text-lg font-medium whitespace-nowrap">
            4.8/5 Rated "Excellent"
          </div>
        </div>

        <span className="text-zinc-600 text-lg font-medium">|</span>

        <div className="flex gap-2">
          <MagicIcon />
          <div className="text-slate-300 text-lg font-medium whitespace-nowrap">
            250 Models Generated Today
          </div>
        </div>
      </div>

      {currentUser ? (
        <div />
      ) : (
        <Button
          color="primary"
          size="sm"
          className="bg-indigo-600 text-lg text-white !font-medium"
          onPress={() => signInWithGoogle()}
          disabled={userLoading}
        >
          Sign In
        </Button>
      )}
    </div>
  );
};

export default Header;
