import React, { createContext, useContext, useEffect, useState } from "react";
import { collection, getDocs, orderBy, query } from "firebase/firestore";
import { db } from "../firebase-config";
import { useAuth } from "./AuthContext";

interface GenerationsContextProps {
  generations: string[];
  loading: boolean;
  currentPage: number;
  totalPages: number;
  handlePreviousPage: () => void;
  handleNextPage: () => void;
}

const GenerationsContext = createContext<GenerationsContextProps | undefined>(undefined);

export const GenerationsProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [generations, setGenerations] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const { currentUser } = useAuth();
  const itemsPerPage = 6;

  useEffect(() => {
    if (currentUser === null) return;

    const fetchGenerations = async () => {
      setLoading(true);
      const localGenerations: string[] = [];
      const q = query(
        collection(db, `User/${currentUser.uid}/Generation`),
        orderBy("created", "desc")
      );

      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        if (data?.status === "DONE") {
          const modelUrl = `https://photoshopai.blob.core.windows.net/3dstudio/${data.dirPath}/result.glb`;
          localGenerations.push(modelUrl);
        }
      });

      setGenerations(localGenerations);
      setTotalPages(Math.ceil(localGenerations.length / itemsPerPage));
      setLoading(false);
    };

    fetchGenerations();
  }, [currentUser]);

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const paginatedGenerations = generations.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  return (
    <GenerationsContext.Provider
      value={{
        generations: paginatedGenerations,
        loading,
        currentPage,
        totalPages,
        handlePreviousPage,
        handleNextPage,
      }}
    >
      {children}
    </GenerationsContext.Provider>
  );
};

export const useGenerations = () => {
  const context = useContext(GenerationsContext);
  if (context === undefined) {
    throw new Error("useGenerations must be used within a GenerationsProvider");
  }
  return context;
};