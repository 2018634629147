import { doc, setDoc, getDoc, onSnapshot, updateDoc, collection, where, query, getDocs, addDoc, Timestamp } from 'firebase/firestore';
// import { v4 as uuidv4 } from 'uuid';
import { db } from '../firebase-config';
import { BaseUser, Generation } from '../types';

export const updateUserDoc = async (uid: string, data: Partial<BaseUser>) => {
  const userRef = doc(db, `User/${uid}`);
  try {
    await updateDoc(userRef, data);
  } catch (error) {
    console.error('Error updating user document:', error);
    throw error;
  }
};


export const upsertUser = async (user: BaseUser) => {
  const userRef = doc(db, `User/${user.uid}`);
  try {
    const doc = await getDoc(userRef);
    if (!doc.exists()) {
      const userObj = { ...user };
      userObj.plan = "FREE";
      await setDoc(userRef, userObj);
      return userObj;
    } else {
      return { ...doc.data() }
    }
  } catch (exc) {
    console.log(exc);
    throw exc;
  }
};

export const getUserDoc = async (uid: string) => {
  const userRef = doc(db, `User/${uid}`);
  try {
    const doc = await getDoc(userRef);
    if (doc.exists()) {
      return doc.data();
    } else {
      return null;
    }
  } catch (exc) {
    console.log(exc);
    throw exc;
  }
};

export const getUserByStripeCustomerId = async (stripeCustomerId: string): Promise<BaseUser | null> => {
  try {
    const usersRef = collection(db, 'User');
    const q = query(usersRef, where('stripeCustomerId', '==', stripeCustomerId));
    const querySnapshot = await getDocs(q);

    if (querySnapshot.empty) {
      return null;
    }

    // Assuming there's only one user with this stripeCustomerId
    const userDoc = querySnapshot.docs[0];
    const userData = userDoc.data() as BaseUser;
    return { ...userData, uid: userDoc.id };
  } catch (error) {
    console.error('Error fetching user by Stripe Customer ID:', error);
    throw error;
  }
};

export const decrementUserGenerations = async (user: BaseUser): Promise<void> => {
  try {
    const userRef = doc(db, `User/${user.uid}`);
    const userDoc = await getDoc(userRef);

    if (!userDoc.exists()) {
      throw new Error('User document not found');
    }

    const userData = userDoc.data();

    await updateDoc(userRef, {
      generationsLeft: userData.generationsLeft - 1
    });
  } catch (error) {
    console.error('Error decrementing user generations:', error);
    throw error;
  }
}

export const createUserGenerationDoc = async (user: BaseUser): Promise<string> => {
  try {
    const generationData: Generation = {
      created: Timestamp.now(),
      dirPath: null,
      status: "PENDING",
      imageUrls: []
    };
    if (user.plan == "FREE") { 
        await decrementUserGenerations(user);
    }
    const userGenerationsRef = collection(db, `User/${user.uid}/Generation`);
    const docRef = await addDoc(userGenerationsRef, generationData);
    return `User/${user.uid}/Generation/${docRef.id}`;
  } catch (error) {
    console.error("Error creating user generation document:", error);
    throw error;
  }
};

export const addApiKey = async (user: BaseUser, newKey: string): Promise<void> => {
  try {
    const apiKeys = user.apiKeys || [];
    await updateUserDoc(user.uid, {
      apiKeys: [...apiKeys, newKey]
    });
  } catch (error) {
    console.error('Error adding API key:', error);
    throw error;
  }
};

export const removeApiKey = async (user: BaseUser, keyToDelete: string): Promise<void> => {
  try {
    const apiKeys = user.apiKeys || [];
    await updateUserDoc(user.uid, {
      apiKeys: apiKeys.filter(key => key !== keyToDelete)
    });
  } catch (error) {
    console.error('Error removing API key:', error);
    throw error;
  }
};
