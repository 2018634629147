import { SVGProps } from "react";
import React from "react";

interface IProps extends SVGProps<SVGSVGElement> {
  className?: string;
}

export const UserGroupIcon: React.FC<IProps> = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 25 25"
      fill="none"
      className={`w-6 h-6 ${className}`} // Tailwind utility classes
    >
      <path
        d="M16.6673 21.875V19.7917C16.6673 18.6866 16.2283 17.6268 15.4469 16.8454C14.6655 16.064 13.6057 15.625 12.5007 15.625H6.25065C5.14558 15.625 4.08577 16.064 3.30437 16.8454C2.52297 17.6268 2.08398 18.6866 2.08398 19.7917V21.875"
        stroke="#CBD5E1"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.37565 11.4583C11.6768 11.4583 13.5423 9.59285 13.5423 7.29167C13.5423 4.99048 11.6768 3.125 9.37565 3.125C7.07446 3.125 5.20898 4.99048 5.20898 7.29167C5.20898 9.59285 7.07446 11.4583 9.37565 11.4583Z"
        stroke="#CBD5E1"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.916 21.8748V19.7915C22.9153 18.8683 22.6081 17.9715 22.0424 17.2418C21.4768 16.5122 20.6849 15.9911 19.791 15.7603"
        stroke="#CBD5E1"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.666 3.26025C17.5623 3.48973 18.3567 4.01098 18.924 4.74183C19.4913 5.47267 19.7992 6.37153 19.7992 7.29671C19.7992 8.22189 19.4913 9.12075 18.924 9.8516C18.3567 10.5824 17.5623 11.1037 16.666 11.3332"
        stroke="#CBD5E1"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const StarIcon: React.FC<IProps> = ({ className }) => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`w-6 h-6 text-sky-500 ${className}`} // Tailwind utility classes
    >
      <path
        d="M12.0058 2.39068C12.0514 2.29845 12.1219 2.22081 12.2094 2.16654C12.2968 2.11226 12.3977 2.0835 12.5006 2.0835C12.6035 2.0835 12.7043 2.11226 12.7918 2.16654C12.8792 2.22081 12.9497 2.29845 12.9954 2.39068L15.4016 7.26464C15.5601 7.58544 15.7941 7.86298 16.0835 8.07344C16.3729 8.28391 16.709 8.421 17.0631 8.47297L22.4443 9.26047C22.5463 9.27524 22.6421 9.31825 22.7209 9.38463C22.7997 9.45102 22.8583 9.53812 22.8902 9.6361C22.922 9.73408 22.9259 9.83902 22.9012 9.93905C22.8765 10.0391 22.8243 10.1302 22.7506 10.2021L18.8589 13.9917C18.6023 14.2418 18.4102 14.5506 18.2994 14.8913C18.1885 15.2321 18.1621 15.5947 18.2224 15.948L19.1412 21.3021C19.1592 21.4041 19.1482 21.509 19.1094 21.6049C19.0707 21.7009 19.0057 21.784 18.922 21.8448C18.8382 21.9057 18.7391 21.9417 18.6358 21.9489C18.5326 21.9561 18.4294 21.9341 18.3381 21.8855L13.5277 19.3563C13.2107 19.1899 12.8581 19.1029 12.5001 19.1029C12.1421 19.1029 11.7894 19.1899 11.4724 19.3563L6.66307 21.8855C6.57175 21.9338 6.46869 21.9556 6.36563 21.9482C6.26256 21.9409 6.16361 21.9048 6.08005 21.844C5.99648 21.7833 5.93165 21.7002 5.89292 21.6045C5.8542 21.5087 5.84313 21.4039 5.86099 21.3021L6.7787 15.949C6.83932 15.5956 6.81306 15.2328 6.70217 14.8918C6.59129 14.5508 6.39912 14.2419 6.14224 13.9917L2.25057 10.2032C2.17619 10.1313 2.12348 10.04 2.09845 9.93971C2.07342 9.83938 2.07707 9.73403 2.10899 9.63567C2.14091 9.53731 2.19981 9.44989 2.27899 9.38337C2.35816 9.31685 2.45443 9.27391 2.55682 9.25943L7.93703 8.47297C8.29146 8.42141 8.62806 8.28449 8.91785 8.074C9.20763 7.86351 9.44193 7.58575 9.60057 7.26464L12.0058 2.39068Z"
        stroke="#FABE24"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const MagicIcon: React.FC<IProps> = ({ className }) => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`w-6 h-6 text-yellow-500 ${className}`} // Tailwind for custom styles
    >
      <path
        d="M10.3501 16.1458C10.2571 15.7853 10.0692 15.4563 9.80596 15.193C9.5427 14.9298 9.21372 14.7419 8.85323 14.6489L2.4626 13.001C2.35357 12.97 2.25761 12.9044 2.18928 12.8139C2.12095 12.7235 2.08398 12.6133 2.08398 12.4999C2.08398 12.3866 2.12095 12.2764 2.18928 12.1859C2.25761 12.0955 2.35357 12.0298 2.4626 11.9989L8.85323 10.3499C9.21359 10.257 9.54249 10.0693 9.80573 9.80623C10.069 9.54317 10.2569 9.2144 10.3501 8.8541L11.998 2.46348C12.0287 2.35402 12.0943 2.25758 12.1848 2.18889C12.2754 2.12019 12.3859 2.08301 12.4996 2.08301C12.6132 2.08301 12.7238 2.12019 12.8144 2.18889C12.9049 2.25758 12.9705 2.35402 13.0011 2.46348L14.648 8.8541C14.741 9.21459 14.9289 9.54358 15.1922 9.80683C15.4554 10.0701 15.7844 10.258 16.1449 10.351L22.5355 11.9979C22.6454 12.0282 22.7423 12.0937 22.8114 12.1844C22.8805 12.2751 22.9179 12.3859 22.9179 12.4999C22.9179 12.6139 22.8805 12.7248 22.8114 12.8155C22.7423 12.9062 22.6454 12.9717 22.5355 13.002L16.1449 14.6489C15.7844 14.7419 15.4554 14.9298 15.1922 15.193C14.9289 15.4563 14.741 15.7853 14.648 16.1458L13.0001 22.5364C12.9695 22.6459 12.9039 22.7423 12.8133 22.811C12.7228 22.8797 12.6122 22.9169 12.4985 22.9169C12.3849 22.9169 12.2743 22.8797 12.1838 22.811C12.0932 22.7423 12.0276 22.6459 11.997 22.5364L10.3501 16.1458Z"
        stroke="#38BDF8"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.834 3.125V7.29167"
        stroke="#38BDF8"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.9167 5.2085H18.75"
        stroke="#38BDF8"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.16602 17.7085V19.7918"
        stroke="#38BDF8"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.20833 18.75H3.125"
        stroke="#38BDF8"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const UploadIcon: React.FC<IProps> = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="37"
      height="37"
      viewBox="0 0 37 37"
      fill="none"
      className={`w-9 h-9 text-purple-600 ${className}`} // Tailwind default sizes with optional custom styles
    >
      <path
        d="M31.7411 23.0005V28.943C31.7411 29.731 31.4281 30.4867 30.8709 31.0439C30.3137 31.6012 29.5579 31.9142 28.7699 31.9142H7.97124C7.18322 31.9142 6.42747 31.6012 5.87026 31.0439C5.31304 30.4867 5 29.731 5 28.943V23.0005"
        stroke="#5856D6"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25.8562 12.4286L18.4281 5.00049L11 12.4286"
        stroke="#5856D6"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.5 5.12939V22.9568"
        stroke="#5856D6"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const BoxIcon: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="23"
      viewBox="0 0 24 23"
      fill="none"
    >
      <path
        d="M20.625 7.66649C20.6247 7.33038 20.5359 7.00027 20.3677 6.70927C20.1995 6.41828 19.9577 6.17663 19.6667 6.00857L12.9583 2.17524C12.667 2.00702 12.3364 1.91846 12 1.91846C11.6636 1.91846 11.333 2.00702 11.0417 2.17524L4.33333 6.00857C4.04225 6.17663 3.80048 6.41828 3.63228 6.70927C3.46407 7.00027 3.37534 7.33038 3.375 7.66649V15.3332C3.37534 15.6693 3.46407 15.9994 3.63228 16.2904C3.80048 16.5814 4.04225 16.823 4.33333 16.9911L11.0417 20.8244C11.333 20.9926 11.6636 21.0812 12 21.0812C12.3364 21.0812 12.667 20.9926 12.9583 20.8244L19.6667 16.9911C19.9577 16.823 20.1995 16.5814 20.3677 16.2904C20.5359 15.9994 20.6247 15.6693 20.625 15.3332V7.66649Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.66211 6.7085L11.9996 11.5002L20.3371 6.7085"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 21.0833V11.5"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const LogoutIcon: React.FC = () => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.5 21H5.5C4.96957 21 4.46086 20.7893 4.08579 20.4142C3.71071 20.0391 3.5 19.5304 3.5 19V5C3.5 4.46957 3.71071 3.96086 4.08579 3.58579C4.46086 3.21071 4.96957 3 5.5 3H9.5"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.5 17L21.5 12L16.5 7"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.5 12H9.5"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const ArrowIcon: React.FC<IProps> = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="8"
      height="15"
      viewBox="0 0 8 15"
      fill="none"
      className={className}
    >
      <path
        d="M7 13.5L1 7.5L7 1.5"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const UnlimitedIcon = (props: IProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        stroke="currentColor"
        d="M12 12c-2-2.67-4-4-6-4a4 4 0 0 0 0 8c2 0 4-1.33 6-4m0 0c2 2.67 4 4 6 4a4 4 0 1 0 0-8c-2 0-4 1.33-6 4"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </svg>
  );
};

export const CustomisationIcon = (props: IProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
      {...props}
    >
      <g
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      >
        <path d="M12 17v4m3.2-16.1-.9-.4m.9 2.6-.9.4m2.6-4.3-.4-.9m.4 6.5-.4.9m3-7.4-.4.9m.4 6.5-.4-.9m2.6-4.3-.9.4m.9 2.6-.9-.4M22 13v2a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h7M8 21h8" />
        <path d="M18 9a3 3 0 1 0 0-6 3 3 0 0 0 0 6" />
      </g>
    </svg>
  );
};

export const ProcessingIcon = (props: IProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="21"
      fill="none"
      viewBox="0 0 20 21"
      {...props}
    >
      <path
        stroke="currentColor"
        d="M19 10.373a9.47 9.47 0 0 1-1.72 5.455 9.02 9.02 0 0 1-4.5 3.371 8.74 8.74 0 0 1-5.561 0 9.02 9.02 0 0 1-4.5-3.371A9.5 9.5 0 0 1 1 10.373c0-1.96.602-3.87 1.719-5.455a9.02 9.02 0 0 1 4.5-3.371 8.74 8.74 0 0 1 5.562 0"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </svg>
  );
};

export const UsageIcon = (props: IProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="25"
      fill="none"
      viewBox="0 0 24 25"
      {...props}
    >
      <path
        stroke="currentColor"
        d="m20 6.747-11 11-5-5"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </svg>
  );
};

export const CloseIcon = (props: IProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="34"
      height="34"
      fill="none"
      viewBox="0 0 34 34"
      {...props}
    >
      <path
        stroke="currentColor"
        d="m21.25 12.75-8.5 8.5m0-8.5 8.5 8.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </svg>
  );
};

export const CopyIcon = (props: IProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="feather feather-copy"
      {...props}
    >
      <rect x="9" y="9" width="13" height="13" rx="2" ry="2" />
      <path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1" />
    </svg>
  );
};

export const VertexIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="12" cy="12" r="4" fill="currentColor" />
  </svg>
);

export const EdgeIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <line
      x1="4"
      y1="12"
      x2="20"
      y2="12"
      stroke="currentColor"
      strokeWidth="2"
    />
  </svg>
);

export const PolyIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 4L20 12L12 20L4 12L12 4Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="2"
    />
  </svg>
);
