import { useThree } from "@react-three/fiber";
import { useEffect } from "react";
import * as THREE from "three";

function SetSceneBackgroundColor() {
  const { scene } = useThree();

  useEffect(() => {
    scene.background = new THREE.Color("#292929"); // Replace "#yourColor" with your desired color
  }, [scene]);

  return null;
}

export default SetSceneBackgroundColor;